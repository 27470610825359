import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import Currency from '../utils/Currency'

class Dashboard extends React.Component{
  state={nav:false, bot:null, summary:{}}
  constructor() {
    super();
  }
  componentDidMount(){
    this.getBot()
    this.getSummary()
  }
  getBot(){
    fetch(window.baseurl+'/bot/me',{
      headers:{
        'content-type':'application/json',
        'token':window.user.token
      }
    }).then((r)=>r.json()).then((res)=>{
      this.setState({bot:res.data})      
    })
  }
  getSummary(){
    fetch(window.baseurl+'/bot/summary',{
      headers:{
        'content-type':'application/json',
        'token':window.user.token
      }
    }).then((r)=>r.json()).then((res)=>{
      this.setState({summary:res.data})      
    })
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  formatRP(num){
    return new Currency().format(num)
  }
  render(){
    const{bot,summary}=this.state
    var curdate  = new Date().getFullYear()+",";
   const {nav}=this.state
    return (  <div>
      <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NKDMSK6" height="0" width="0" style="display:none;visibility:hidden">
            </iframe>
          </noscript>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'dashboard'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      <Header title={'Dashboard'} toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      
      <div className="w-full px-6 py-6 mx-auto">
      
      <div className="flex flex-wrap -mx-3">
      
      <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
      <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="flex-auto p-4">
      <div className="flex flex-row -mx-3">
      <div className="flex-none w-2/3 max-w-full px-3">
      <div>
      <p className="mb-0 font-sans font-semibold leading-normal uppercase dark:text-white dark:opacity-60 text-sm">SALDO</p>
      <h5 className="mb-2 font-bold dark:text-white">{this.formatRP(bot?.balance)}</h5>
      <p className="mb-0 dark:text-white dark:opacity-60">.
      </p>
      </div>
      </div>
      <div className="px-3 text-right basis-1/3">
      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
      <i className="ni ni-money-coins text-lg relative top-3.5 text-white"></i>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
      <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
      <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="flex-auto p-4">
      <div className="flex flex-row -mx-3">
      <div className="flex-none w-2/3 max-w-full px-3">
      <div>
      <p className="mb-0 font-sans font-semibold leading-normal uppercase dark:text-white dark:opacity-60 text-sm">WHATSAPP STATUS</p>
      {bot&&<>
      {bot.connected?<h5 className="mb-2 font-bold text-green-400">Connected</h5>:
      <h5 className="mb-2 font-bold text-red-400">Disconnect</h5>}
      </>}
      <p className="mb-0 dark:text-white dark:opacity-60">.
      </p>
      </div>
      </div>
      <div className="px-3 text-right basis-1/3">
      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
      <i className="ni ni-world text-lg relative top-3.5 text-white"></i>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
      <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
      <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="flex-auto p-4">
      <div className="flex flex-row -mx-3">
      <div className="flex-none w-2/3 max-w-full px-3">
      <div>
      <p className="mb-0 font-sans font-semibold leading-normal uppercase dark:text-white dark:opacity-60 text-sm">CUSTOMERS</p>
      <h5 className="mb-2 font-bold dark:text-white">{summary.customers?summary.customers:0}</h5>
      <p className="mb-0 dark:text-white dark:opacity-60">
      <span className="font-bold leading-normal text-red-600 text-sm"></span>
      .
      </p>
      </div>
      </div>
      <div className="px-3 text-right basis-1/3">
      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
      <i className="fa fa-users text-lg relative top-3.5 text-white"></i>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
      <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
      <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="flex-auto p-4">
      <div className="flex flex-row -mx-3">
      <div className="flex-none w-2/3 max-w-full px-3">
      <div>
      <p className="mb-0 font-sans font-semibold leading-normal uppercase dark:text-white dark:opacity-60 text-sm">CAMPAIGN</p>
      <h5 className="mb-2 font-bold dark:text-white">{summary.campaigns?summary.campaigns:0}</h5>
      <p className="mb-0 dark:text-white dark:opacity-60">
      <span className="font-bold leading-normal text-sm text-emerald-500"></span>
      .
      </p>
      </div>
      </div>
      <div className="px-3 text-right basis-1/3">
      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
      <i className="fa fa-bullhorn text-lg relative top-3.5 text-white"></i>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <Footer/>
      </div>
      
      </main>
     
       </div>)
  }
}

export default Dashboard;
