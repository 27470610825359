import React from "react";
import Item from './Item'

class Uploader extends React.Component{
  state={files_uploaded:[]}
  constructor() {
    super();
    
  }
  OnBrowse(e){
    var {files_uploaded}=this.state
    window.xx=e
    var files = e.target?.files
    for(var i=0;i<files.length;i++){
      files_uploaded.push(files[i])     
    }
    this.setState({files_uploaded})
  }
  OnItemClose(i){ 
    var {files_uploaded}=this.state
    var files = files_uploaded.filter((f,x)=>{
      if(x!=i) return f
    })
    
    this.setState({files_uploaded:files})
  }
  render(){
    const {files_uploaded}=this.state
    const {title}=this.props
   return   <div className="col-span-full">
             <label for="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">{title}</label>
             <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
               <div className="text-center">
               <div className="grid gap-4 grid-cols-3">
               {files_uploaded.map((f,i)=><Item file={f} key={i} xkey={i} OnClose={()=>this.OnItemClose(i)} />)}
               </div>
                 {files_uploaded.length<1&&<svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                   <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                 </svg>}
                 <div className="mt-4 flex text-sm leading-6 text-gray-600">
                 
                   <label for="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                     <span>Upload a file</span>
                     <input id="file-upload" name="file-upload" multiple onChange={this.OnBrowse.bind(this)} type="file" className="sr-only"/>
                   </label>
                   <p className="pl-1">or drag and drop</p>
                 </div>
                 <p className="text-xs leading-5 text-gray-600">PNG, JPG, Etc up to 10MB</p>
               </div>
             </div>
           </div>
  }
}

export default Uploader;
