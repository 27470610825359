import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import SocketEventHandler from '../utils/SocketEventHandler'

class Qr extends React.Component{
  state={qr:null, nav:false}
  constructor() {
    super();
    
  }
componentDidMount(){
  SocketEventHandler.on('qr',(data)=>{
    console.log("on QR")
    this.setState({qr:data.qr})
  })
  SocketEventHandler.on('ready',(data)=>{
    this.setState({qr:'Bot connected & ready'})
  })
}
toggleNav(){
  const {nav}=this.state
  this.setState({nav:!nav})
}
  render(){
    
   const {qr, nav}=this.state
    return (  <div>
      <div class="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'Qr'}/>
      
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header toggleNav={this.toggleNav.bind(this)}
      open={nav}
       title={'QR'}/>
      
      
      <div class="w-full px-6 py-6 mx-auto">
      
    <pre className="text-left leading-none mx-auto bg-white">{qr}</pre>

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default Qr;
