import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import GptForm from '../components/Forms/GptForm'
import GptConfigForm from '../components/Forms/GptConfigForm'
import withRouter from '../components/Layouts/withRouter'

class Botgpt extends React.Component{
  state={nav:false}
  constructor() {
    super();
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }

  render(){
    const{nav}=this.state
    const {params}=this.props
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'gpt'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header  toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      
      <div className="w-full px-6 py-6 mx-auto">
      {params.id?<GptConfigForm/>:<GptForm/>}

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(Botgpt)
