import React from "react";
import { DateTime } from "luxon";
class ChatDetail extends React.Component{
  state={formdata:{}}
  constructor() {
    super();
  }
  onChange(e){
    const {formdata}=this.state
    formdata[e.target.name]=e.target.value
    this.setState({formdata})
  }
  reply(){
    const {OnReply}=this.props
    let {formdata}=this.state
    OnReply(formdata, this.resetForm.bind(this))
  }
  resetForm(){ 
    let {formdata}=this.state
    formdata={}
    this.setState({formdata})
  }   
  render(){
    const {formdata}=this.state
    const {data}=this.props

    return <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
    {data&&<div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl flex mx-2">
    <img src={data.customer?.avatar?data.customer?.avatar:window.home+'/img/unpic.png'} className="object-cover inline-flex items-center justify-center mr-4 text-white transition-all duration-200 ease-in-out text-sm h-9 w-9 rounded-xl" alt="user1" />
                  <h6>{data?.customer?.name}</h6>
    </div>}
    <div className="flex-auto px-0 pt-0 pb-2 border-t-2 mt-2 border-solid ">
      {data&&<div className="p-0 overflow-x-auto max-h-96">
          {data.messages.map((msg,i)=>{
            if(msg.content.type==='e2e_notification') return null
            let tm = DateTime.fromSeconds(msg.content.t)
            let read ='text-sky-400'
            let cls ="border border-transparent border-solid shadow-sm mt-4 rounded-2xl p-2 ml-6 w-1/2"
            return <div className={"flex justify-"+(msg.content.id.fromMe?"end ":"start")}>
              <div className={cls+(msg.content.id.fromMe?" bg-stone-200/50":" bg-green-700/20")}>
                <div className="flex justify-between ">
                {msg.content.type==='image'&&
                <div ><img className="w-full flex" src={"data:image/png;base64, "+msg.content.body}/>
                <p><pre className="whitespace-break-spaces">{msg.content.caption}</pre></p></div>}
                  
                {msg.content.type==='imachatge'&&<p>{msg.content.body.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>}
                <span className="flex flex-col justify-end" style={{fontSize:8}}>
                  <i className={"fa fa-check  "+(msg.status>2?read:"")}></i>
                 {msg.status>1 && <i className={"fa fa-check  -mt-1 "+(msg.status>2?read:"")}></i>}
                </span>
                </div>
                <span className="text-xs flex justify-end">{tm.day} {tm.month} {tm.year} . {tm.hour}:{tm.minute}</span>
            </div> </div>
          })}
      </div>}
      <div className="p-4 ">
      <textarea name="message" rows="4" placeholder="Tulis pesan..." 
      onChange={this.onChange.bind(this)}
      value={formdata.message?formdata.message:""}
      className="focus:shadow-primary-outline min-h-unset text-sm leading-5.6 ease block h-auto w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none">
        
      </textarea>
      <div className="flex justify-end pt-2">
        <button type="button" 
        onClick={this.reply.bind(this)}
        className="mr-3 inline-block px-6 py-2 font-bold text-center bg-gradient-to-tl from-blue-500 to-violet-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white">Kirim</button>
</div>
      </div>
    </div>
  </div>
  }
}

export default ChatDetail;
