import React from "react";



class CustomFields extends React.Component{
  constructor() {
    super();
    
  }
OnChange(e){
  let {formdata}=this.state
  formdata[e.target.name]=e.target.value
  this.setState({formdata})
  /*window.socket.timeout(5000).emit('create', {value:e.target.value}, () => {
    console.log('socket is sending: '+e.target.value)
  });
  */
}
  render(){
    return  <div class="fixed top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto transition-opacity ease-linear z-sticky outline-0" id="import" aria-hidden="true">
      <div class="relative w-auto p-4 md:w-1/3 m-2 transition-transform duration-300 ease-out pointer-events-none  mx-auto md:mt-28 lg:mt-48 -translate-y-13">
        <div class="relative flex flex-col w-full bg-white  border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
          <div class="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 class="mb-0 leading-normal dark:text-white" id="ModalLabel">Tambahkan Custom Field</h5>
              </div>
          <div class="relative flex justify-between p-4">
            <div>
            <input type="text" placeholder="Nama field" className="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
            </div>
            <div className="-ml-6">
                <select
                  id="country"
                  name="country"
                  class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full  rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                >
                  <option disabled selected>Tipe data</option>
                  <option>Text</option>
                  <option>Longtext</option>
                  <option>Number</option>
                </select>
            </div>

          </div>
          <div class="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl">
            <button type="button" data-toggle="modal" class="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">Batal</button>
            <button type="button" data-toggle="modal" class="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">Simpan</button>
          </div>
        </div>
      </div>
    </div>
      }
}

export default CustomFields;
