import React from "react";
import {
  Link
} from "react-router-dom";

class ChatsList extends React.Component{
  
  constructor() {
    super();
    
  }
  render(){
    const {data, title}=this.props
    return <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
    <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
      <h6>{title}</h6>
    </div>
    <div className="flex-auto px-0 pt-0 pb-2">
      <div className="p-0 overflow-x-auto">
        {data&&<table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
          <thead className="align-bottom">
            <tr>
              <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Customer</th>
              <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Message</th>
              <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((ch,i)=>{
              let last = ch.messages[ch.messages.length-1]
              let unread = ch.messages.filter((m)=>m.status==1)
            return <tr key={i}>
              <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                <Link to={'/chat/'+ch.cid} className="flex px-2 py-1">
                  <div>
                    <img src={ch.customer?.avatar?ch.customer.avatar:'./img/unpic.png'} className="object-cover inline-flex items-center justify-center mr-4 text-white transition-all duration-200 ease-in-out text-sm h-9 w-9 rounded-xl" alt="user1" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <h6 className="mb-0 leading-normal text-sm">{ch.customer?.name}</h6>
                    <p className="mb-0 leading-tight text-xs text-slate-400">{ch.cid}</p>
                  </div>
                </Link>
              </td>
              <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                <p className="mb-0 leading-tight text-xs text-slate-400">{last.content.body.substr(0,120)}... {last.status<2&&<span className="text-xs p-1 px-2 font-medium bg-green-400 rounded-full text-white">{unread.length}</span>}
                </p>
              </td>
              <td className="p-2 leading-normal text-center align-middle bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
            {ch.updated_at}
             </td>
            </tr>
              
            })}
          </tbody>
        </table>}
      </div>
    </div>
  </div>
  }
}

export default ChatsList;
