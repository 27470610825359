import React from "react";
import Customer from '../Tables/Customer'



class SegmentCustomerForm extends React.Component{
  state={formdata:{},customer:null,all:true}
  constructor() {
    super();
    
  }
  componentDidMount(){
    this.getCustomer()
  }
  getCustomer(){
      fetch(window.baseurl+'/customers',{
        headers:{
          "Content-Type": "application/json",
          'token':window.user?.token 
        }
  
      }).then((r)=>r.json()).then((res)=>{
        this.setState({customer:res})
      })
    }
  OnCheckList(data){
  let {formdata}=this.state
  formdata=data
  this.setState({formdata})
}
onSelectAll(){
  const {all,customer}=this.state
 if(customer){
  customer.forEach(c => {
    c.selected=all
  });
  let l = customer.filter((c)=>c.selected)   
  this.setState({customer,customers_selected:l,all:!all},()=>this.OnCheckList(l))
 } 
}
  render(){
    const {visible, onCancel, onSave, title,onSelectAll}=this.props
    let {formdata, customer}=this.state
    return  <div className={"fixed top-0 left-0 w-full h-full bg-black/30 overflow-x-hidden overflow-y-auto transition-opacity ease-linear z-sticky outline-0 "+(visible?"":"hidden")}  aria-hidden="true">
      <div className="relative w-auto p-4 md:w-3/4 m-2 transition-transform duration-300 ease-out pointer-events-none  mx-auto md:mt-28 lg:mt-48 -translate-y-13">
        <div className="relative flex flex-col w-full bg-white  border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal dark:text-white" id="ModalLabel">{title}</h5>
              </div>
              <Customer
            modal_able={false}
            data={customer}            
          onSelectAll={this.onSelectAll.bind(this)}
            onCheckList={this.OnCheckList.bind(this)}
            />
          <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl">
            <button type="button" onClick={onCancel} data-toggle="modal" className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">Batal</button>
            <button type="button" onClick={()=>{
              onSave(formdata)
              this.setState({formdata:{}})
            }} data-toggle="modal" className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">Simpan</button>
          </div>
        </div>
      </div>
    </div>
      }
}

export default SegmentCustomerForm;
