import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import {DocumentForm} from '../components/Form'
class CustomerDocuments extends React.Component{
  constructor() {
    super();
  }

  render(){
    
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header/>
      <DocumentForm/>
      
      <Footer/>
      
      </main>
     
     </div>)
  }
}

export default CustomerDocuments;
