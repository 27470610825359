import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import Segment from '../components/Tables/Segment'
class SegmentList extends React.Component{
  state={segments:null, nav:false}
  constructor() {
    super();
  }
componentDidMount(){
  this.getSegments()
}
  getSegments(){
    fetch(window.baseurl+'/customers/segments',{
      headers:{
        'content-type':'application/json',
        'token':window.user.token
      }
    }).then((r)=>r.json()).then((res)=>{
      this.setState({segments:res})
    })
  }

  onSelected(data){
    this.setState({selected:data})
  }
  onSelectedAll(all){ 
    let {segments}=this.state
   if(segments){
    segments.forEach(c => {
      c.selected=all
    });
    let l = segments.filter((c)=>c.selected)  
    this.setState({selected:l,})
   } 
  }
  onDelete(){
    const {selected}=this.state
    fetch(window.baseurl+"/customers/segments/delete",{method:'post',
    headers:{
      "Content-Type": "application/json",
      'token':window.user?.token 
    },
    body:JSON.stringify({data:selected})
  }).then((res)=>res.json()).then((js)=>{
    this.getSegments()
  })

  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  render(){
    const {segments, nav}=this.state
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'segments'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header title={'Segment'} toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      <div className="w-full px-6 py-6 mx-auto">
      <Segment data={segments} onAdd={this.getSegments.bind(this)}
      onSelected={this.onSelected.bind(this)}
      onSelectedAll={this.onSelectedAll.bind(this)}
      onDelete={this.onDelete.bind(this)}
      />

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default SegmentList;
