import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import GptFlowForm from '../components/Forms/GptFlowForm'
import withRouter from '../components/Layouts/withRouter'

class BotgptFlow extends React.Component{
  state={nav:false, flow:null}
  constructor() {
    super();
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  componentDidMount(){
    const {params}=this.props
    if(params.fid!=='0')this.getFlow(params.fid)
  }
  getFlow(fid){
    fetch(window.baseurl+'/bot/context_flow/'+fid,{
      headers:{
        'content-type':'application/json', 'token':window.user?.token
      }
    }).then((r)=>r.json())
    .then((res)=>{
      this.setState({flow:res.data})
    })
  }
  render(){
    const{nav, flow}=this.state
    const {params}=this.props
    return (  <div>
      <div class="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'gpt'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      <Header  toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      <div class="w-full px-6 py-6 mx-auto">
      <GptFlowForm context_id={params.id} flow={flow}/>

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(BotgptFlow)
