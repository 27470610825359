import React from "react";

class Login extends React.Component{
  state={user:null,error:null,formdata:{}}
  constructor() {
    super();
  }
  submit(){
    let {formdata}=this.state
    let ok=false
    fetch(window.baseurl+'/bot/user/login',{
      method:'post',
      headers:{ 'Content-Type':'Application/json'},
      body:JSON.stringify(formdata)
    }).then((r)=>{ 
      ok=r.ok
      return r.json();
      
    }).then((res)=>{ 
      if (ok) {
        this.setCookie('xtoken',res.token)
      }else{
        return Promise.reject(res);
      }
    }).catch((err)=>{ 
      this.setState({error:err})
  })
  }
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + ((1000*60*60)*24))
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    window.location='/dashboard'
  }
  OnChange(e){
    let {formdata}=this.state
    formdata[e.target.name]=e.target.value
    this.setState({formdata})    
  }
  render(){
    const {error}=this.state
    return (  <div className="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
    <div className="container z-1">
    <div className="flex flex-wrap -mx-3">
    <div className="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
    <div className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 dark:bg-gray-950 rounded-2xl bg-clip-border">
    <div className="p-6 pb-0 mb-0">
    <h4 className="font-bold">Login</h4>
    <p className="mb-0">Gunakan nomor HP dan password untuk login</p>
    </div>
    <div className="flex-auto p-6">
    <form role="form">
    <div className="mb-4">
    <input type="text" name="phone" onChange={this.OnChange.bind(this)} placeholder="No HP" className="focus:shadow-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"/>
    </div>
    <div className="mb-4">
    <input type="password" name="password" onChange={this.OnChange.bind(this)} placeholder="Password" className="focus:shadow-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"/>
    </div>
    {error&&<div className="relative w-full p-4 text-white bg-red-500 rounded-lg"><b>Error!</b> {error.message} </div>}
    <div className="flex items-center pl-12 mb-0.5 text-left min-h-6">
    <input id="rememberMe" className="mt-0.5 rounded-10 duration-250 ease-in-out after:rounded-circle after:shadow-2xl after:duration-250 checked:after:translate-x-5.3 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-zinc-700/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-blue-500/95 checked:bg-blue-500/95 checked:bg-none checked:bg-right" type="checkbox"/>
    <label className="ml-2 font-normal cursor-pointer select-none text-sm text-slate-700" for="rememberMe">Ingat saya</label>
    </div>
    <div className="text-center">
    <button onClick={this.submit.bind(this)} type="button" className="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-blue-500 border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25">Sign in</button>
    </div>
    </form>
    </div>
    <div className="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 text-center pt-0 px-1 sm:px-6">
    <p className="mx-auto mb-6 leading-normal text-sm">-</p>
    </div>
    </div>
    </div>
    <div className="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
    <div className="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')] rounded-xl ">
    <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
    <h4 className="z-20 mt-12 font-bold text-white">"Attention is the new currency"</h4>
    <p className="z-20 text-white ">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
    </div>
    </div>
    </div>
    </div>
    </div>)
  }
}

export default Login;
