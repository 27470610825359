import React from "react";
import Customer from '../Tables/Customer'


class CampaignForm extends React.Component{
  state={segments:[], all:true, segment_selected:null,formdata:{}}
  constructor() {
    super();
    
  }
  componentDidMount(){
    this.getSegments()
  }
    getSegments(){
      fetch(window.baseurl+'/customers/segments',{
        headers:{
          'content-type':'application/json', 'token':window.user?.token
        },
  
      }).then((r)=>r.json()).then((res)=>{
        this.setState({segments:res})
      })
    }

submit(){
  let {customers_selected,formdata,segment_selected}=this.state
  formdata.segment_selected = segment_selected._id
  formdata.customers=customers_selected
  let ok=false
  fetch(window.baseurl+'/campaign/send',{
    headers:{
      'content-type':'application/json', 'token':window.user?.token
    },
    method:'post',
    body:JSON.stringify(formdata)
  }).then((r)=>{ 
    ok=r.ok
    return r.json();      
  }).then((res)=>{ 
    if (ok) {
      window.Swal.fire({
        icon: 'success',
        title: 'Berhasil...',
        text: 'Campaign terjadwal',
      })
      formdata={}
      this.setState({formdata,segment_selected:null})
    }else{
      return Promise.reject(res);
    }
  }).catch((err)=>{ 
  
})
 
}
OnCheckList(data){
let {customers_selected}=this.state
customers_selected=data
this.setState({customers_selected})
}
OnChange(e){
  let {formdata}=this.state
  formdata[e.target.name]=e.target.value
  this.setState({formdata})
}
onSelectAll(){
  const {all,segment_selected}=this.state
  let customers = segment_selected.customers
 if(customers){
  customers.forEach(c => {
    c.selected=all
  });
  let l = customers.filter((c)=>c.selected)   
  segment_selected.customers=customers
  this.setState({segment_selected,customers_selected:l,all:!all})
 } 
}
OnSegmentSelected(e){
  const {segments}=this.state
  this.setState({segment_selected:segments[e.target.value]})
}
  render(){
    const {segments, segment_selected,formdata}=this.state
    return  <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div
      className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
      <div
        className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Campaign Form</h2>
     
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
            Nama Campaign
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="name"
              value={formdata.name}
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
           onChange={this.OnChange.bind(this)}
           />
          </div>
        </div>
        
        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
            Isi Pesan
          </label>
          <div className="mt-2.5">
            <textarea
              name="message"
              id="message"
              rows={4}
              onChange={this.OnChange.bind(this)}
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={''}
              value={formdata.message}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
        <div>
          <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
           Pilih Segment
          </label>
          <div className="mt-2.5">
           {segments.length>0&& <select
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
         onChange={this.OnSegmentSelected.bind(this)} >
          <option >Pilih</option>
            {segments.map((seg,i)=>{
              return <option key={i} value={i}>{seg.name}</option>
            })}
            </select>}
          </div>
        </div>
        </div>
       {segment_selected &&<Customer
      title={"Customer Segment "+segment_selected?.name}
      selectAll={true}
      modal_able={false}
      segment_id={segment_selected?.id}
      data={segment_selected?.customers}
      onSelectAll={this.onSelectAll.bind(this)}
      onCheckList={this.OnCheckList.bind(this)}
      />}
      </div>
      <div className="mt-10">
        <button
          type="button"
          onClick={this.submit.bind(this)}
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Selanjutnya
        </button>
      </div>
    </form>
  </div>
  }
}

export default CampaignForm;
