import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import Campaign from '../components/Tables/Campaign'
class CampaignList extends React.Component{
  state={campaigns:null, nav:false}
  constructor() {
    super();
  }
componentDidMount(){
  this.getCampaigns()
}
  getCampaigns(){
    fetch(window.baseurl+'/campaigns',{
      headers:{
        'content-type':'application/json',
        'token':window.user?.token
      }
    }).then((r)=>r.json()).then((res)=>{
      this.setState({campaigns:res})
    })
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  render(){
    const {nav}=this.state
    const {campaigns}=this.state
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'campaigns'} />
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header toggleNav={this.toggleNav.bind(this)}
      open={nav}
       title={'Campaign'}/>
      
      <div className="w-full px-6 py-6 mx-auto">
      <Campaign 
      data={campaigns?.data}
      title={'Campaign List'}
      />

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default CampaignList;
