import React from "react";

const constracts=[
  {
    amount:'500.000', completetion:'60', status:'progress', tenor:6
  },
  {
    amount:'1200.000', completetion:'70', status:'progress', tenor:6
  },
  {
    amount:'900.000', completetion:'20', status:'progress', tenor:4
  },
  {
    amount:'400.000', completetion:'90', status:'progress', tenor:3
  }
]
class Leeds extends React.Component{
  
  constructor() {
    super();
    
  }
  render(){
    return (<div className="flex-none w-full max-w-full px-3">
    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
    <h6 className="dark:text-white">Leeds</h6>
    </div>
    <div className="flex-auto px-0 pt-0 pb-2">
    <div className="p-0 overflow-x-auto ps">
    <table className="items-center justify-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
    <thead className="align-bottom">
    <tr>
    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Nomor</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Amount</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Tenor</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Status</th>
    <th className="px-6 py-3 pl-2 font-bold text-center uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Completion</th>
    <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-solid shadow-none dark:border-white/40 dark:text-white tracking-none whitespace-nowrap"></th>
    </tr>
    </thead>
    <tbody className="border-t">
      {constracts.map((c,i)=>{
        return   <tr key={i}>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <div className="flex px-2">
    <div className="my-auto">
    <h6 className="mb-0 text-sm leading-normal dark:text-white">MKK0{i}</h6>
    </div>
    </div>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">Rp.{c.amount}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.tenor}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <span className="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">{c.status}</span>
    </td>
    <td className={"p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent"}>
    <div className="flex items-center justify-center">
    <span className="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">{c.completetion}%</span>
    <div>
    <div className="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
    <div className="flex flex-col justify-center  h-auto overflow-hidden text-center text-white transition-all bg-blue-500 rounded duration-600 ease bg-gradient-to-tl from-blue-700 to-cyan-500 whitespace-nowrap" role="progressbar" aria-valuenow={c.completetion} aria-valuemin="0" aria-valuemax="100" style={{width:c.completetion+'%'}}></div>
    </div>
    </div>
    </div>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <button className="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400">
    <i className="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60" aria-hidden="true"></i>
    </button>
    </td>
    </tr>
  
})}
    </tbody>
    </table>
    <div className="ps__rail-x" style={{left: 0,bottom: 0}}>
      <div className="ps__thumb-x" tabindex="0" style={{left: 0,bottom: 0}}></div>
      </div>
      <div className="ps__rail-y" style={{left: 0,bottom: 0}}>
        <div className="ps__thumb-y" tabindex="0" style={{left: 0,bottom: 0}}></div>
        </div></div>
    </div>
    </div>
    </div>)
  }
}

export default Leeds;
