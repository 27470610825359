import React from "react";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import {Uploader} from '../Media'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


class DocumentForm extends React.Component{
  state={agreed:false, locationError:"", coordinate:null}
  constructor() {
    super();
    
  }
  jobs_list = ["Pedagang","Karyawan Swasta","Wiraswasta","Petani","Ibu Rumah Tangga","Mahasiswa/Pelajar","Guru","Serabutan"]
setAgreed(i){
  this.setState({agreed:i})
}
 getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((pos)=>{
     this.setState({coordinate:pos.coords})
    });
  } else {
    this.setState({locationError: "Geolocation is not supported by this browser."})
  }
}
  render(){
    const {agreed,coordinate}=this.state
    return  <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent bg-white px-6 py-12 mx-24 sm:py-14 lg:px-8">
    <div
      className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
      <div
        className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Dokumen</h2>
     
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            KTP
          </label>
          <div className="mt-2.5">
            <Uploader />
          </div>
        </div>
      </div>
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Slip Gaji
          </label>
          <div className="mt-2.5">
            <Uploader />
          </div>
        </div>
      </div>
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Lokasi Usaha
          </label>
          <div className="mt-2.5">
            <Uploader />
          </div>
        </div>
      </div>
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Identitas
          </label>
          <div className="mt-2.5">
            <Uploader />
          </div>
        </div>
      </div>
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
            Lainnya
          </label>
          <div className="mt-2.5">
            <Uploader />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <button
          type="submit"
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Simpan
        </button>
      </div>
    </form>
  </div>
  }
}

export default DocumentForm;
