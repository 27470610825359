import React from "react";



class SegmentModalForm extends React.Component{
  state={formdata:{}, defaultName:null}
  constructor() {
    super();
  }
  componentDidUpdate(){
    let {formdata, defaultName}=this.state
    const {value }=this.props
    if(value && defaultName!==value.name){ 
      formdata=value
      this.setState({formdata,defaultName:value.name})
    }

  }
OnChange(e){
  let {formdata}=this.state
  formdata[e.target.name]=e.target.value
  this.setState({formdata})
  /*window.socket.timeout(5000).emit('create', {value:e.target.value}, () => {
    console.log('socket is sending: '+e.target.value)
  });
  */
}
  render(){
    const {visible, onCancel, onSave}=this.props
    let {formdata}=this.state
    return  <div className={"fixed top-0 left-0 w-full h-full bg-black/30 overflow-x-hidden overflow-y-auto transition-opacity ease-linear z-sticky outline-0 "+(visible?"":"hidden")}  aria-hidden="true">
      <div className="relative w-auto p-4 md:w-1/3 m-2 transition-transform duration-300 ease-out pointer-events-none  mx-auto md:mt-28 lg:mt-48 -translate-y-13">
        <div className="relative flex flex-col w-full bg-white  border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal dark:text-white" id="ModalLabel">Buat segment baru? {(visible?"visible":"hidden")}</h5>
              </div>
          <div className="relative p-4">
            <div>
            <input type="text" onChange={this.OnChange.bind(this)}
            defaultValue={formdata?.name}
            name="name" placeholder="Nama segment" className="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
            </div>

          </div>
          <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl">
            <button type="button" onClick={onCancel} data-toggle="modal" className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">Batal</button>
            <button type="button" onClick={()=>{ 
              onSave(formdata)
              this.setState({formdata:[]})
            }} data-toggle="modal" className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">Simpan</button>
          </div>
        </div>
      </div>
    </div>
      }
}

export default SegmentModalForm;
