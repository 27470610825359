import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import CampaignMessage from '../components/Tables/CampaignMessage'
import withRouter from '../components/Layouts/withRouter'
class CampaignMessageList extends React.Component{
  state={campaign:null, nav:false}
  constructor() {
    super();
  }
componentDidMount(){
  this.getCampaigns()
}
  getCampaigns(){
    fetch(window.baseurl+'/campaign/'+this.props.params.id,{
      headers:{
        'content-type':'application/json',
        'token':window.user?.token
      }
    }).then((r)=>r.json()).then((res)=>{
      this.setState({campaign:res.data})
    })
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }

  render(){
    const {campaign, nav}=this.state
    let campaignObj = campaign?.campaign
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'campaigns'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header  toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      <div className="w-full px-6 py-6 mx-auto">
      <CampaignMessage 
      data={campaign?.messages}
      title={campaignObj?'Campaign '+campaignObj.name:''}
      />

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(CampaignMessageList);
