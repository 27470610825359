import React from "react";
import { Link } from "react-router-dom";


class GptForm extends React.Component{
  state={segments:[], all:true, segment_selected:null,formdata:{}}
  constructor() {
    super();
  }
  componentDidMount(){}

submit(){
  let {formdata}=this.state
  let ok=false
  fetch(window.baseurl+'/bot/context',{
    headers:{
      'content-type':'application/json', 'token':window.user?.token
    },
    method:'post',
    body:JSON.stringify(formdata)
  }).then((r)=>{ 
    ok=r.ok
    return r.json();      
  }).then((res)=>{ 
    if (ok) {      
    this.setState({formdata:res.data})
    }else{
      return Promise.reject(res);
    }
  }).catch((err)=>{ 
  
})
 
}
searchBot(){
  const {formdata}=this.state
  let ok=false
  fetch(window.baseurl+'/bot/context/'+formdata.bot_id_search,{
    headers:{
      'content-type':'application/json', 'token':window.user?.token
    }
  }).then((r)=>{ 
    ok=r.ok
    return r.json();      
  }).then((res)=>{ 
    if (ok) {      
      this.setState({formdata:res.data})
    }else{
      return Promise.reject(res);
    }
  }).catch((err)=>{ 
  
})
}
OnChange(e){
  let {formdata}=this.state
  formdata[e.target.name]=e.target.value
  this.setState({formdata})
}
  render(){
    const {formdata}=this.state
    return  <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div
      className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
      <div
        className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bot GPT Config</h2>
     
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="sm:col-span-2 ">
      <div className="grid grid-cols-5 ">
        <div className="col-span-4"> 
          <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
            Cari Nomor BOT
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="bot_id_search"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={this.OnChange.bind(this)}
           />
          </div>
        </div>
        <div className="col-span-1">
        <button type="button" 
        onClick={this.searchBot.bind(this)}
        className="mt-8 ml-4 inline-block px-6 py-3 mr-3 font-bold text-center text-white uppercase align-middle transition-all bg-blue-500 rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md">
        <i class="fas fa-search"></i></button>

        </div>
        </div>
       
      <div className="sm:col-span-2 ">
      <div > 
          <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
            Nomor BOT
          </label>
          <div className="mt-2.5">
            <input
            disabled
              type="text"
              name="bot_id"
              value={formdata.bot_id}
              autoComplete="given-name"
              className="block w-full bg-gray-200 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          
           />
          </div>
        </div>
      </div>
        
        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
           Konteks
          </label>
          <div className="mt-2.5">
            <textarea
              name="descriptions"
              rows={16}
              onChange={this.OnChange.bind(this)}
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={''}
            value={formdata.descriptions}
            ></textarea>
          </div>
        </div>
        <div className="sm:col-span-2">
       
        </div>
      </div>
      <div className="mt-10">
        <button
          type="button"
          onClick={this.submit.bind(this)}
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Simpan
        </button>
        {formdata.flows&&<ul className="mt-6"><li className="font-semibold">Flows:</li>
        {formdata.flows.map((f,i)=>{
          return <li key={i} className="border-b-2 p-2 rounded-md shadow-sm">
            <Link  to={'/botgpt/flow/'+formdata._id+'/'+f._id} >{f.name}</Link>
          </li>
        })}
        </ul>}
        {formdata.bot_id&&<Link
        to={'/botgpt/flow/'+formdata._id+'/0'}
          className="block w-full rounded-md bg-green-500 mt-4 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Flow
        </Link>}
      </div>
      <p>
      </p>
    </form>
  </div>
  }
}

export default GptForm;
