import React from 'react';
import './config';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


let ok=false
const root = ReactDOM.createRoot(document.getElementById('root'));
auth().then((user)=>{
  window.user=user
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
}).catch(()=>{  
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
})


function auth(){
  let token = getCookie('xtoken')
  return new Promise((solve, reject)=>{
    fetch(window.baseurl+'/token',{
      method:'post',
      headers:{ 'Content-Type':'Application/json'},
      body:JSON.stringify({token:token})
    }).then((r)=>{ 
      ok=r.ok
      return r.json();
      
    }).then((res)=>{ 
      if (ok) {
        solve(res)
      }else{
        return Promise.reject(res);
      }
    }).catch((err)=>{ 
      reject(err)
  })
  })
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
