import React from "react";
import {
  Link,
} from "react-router-dom";



class Sidebar extends React.Component{
  constructor() {
    super();
  }

  render(){
    const {open, item}=this.props
    let classAside='fixed inset-y-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto antialiased transition-transform duration-200 -translate-x-full bg-white border-0 dark:shadow-none dark:bg-slate-850 xl:ml-6 max-w-64 ease-nav-brand z-990 rounded-2xl xl:left-0 xl:translate-x-0 ps '
   let show=open?"translate-x-0 ml-6" :'shadow-xl'
   let selected=[]
   let classItem="py-2.7  dark:text-white dark:opacity-80 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors"
   selected[item]='bg-blue-500/13'
   return   <aside className={classAside+show} aria-expanded="false">
    <div className="h-19">
    <i className="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times dark:text-white text-slate-400 xl:hidden" sidenav-close="true"></i>
    <a className="block px-8 py-6 m-0 text-sm whitespace-nowrap dark:text-white text-slate-700">
    <img src={window.home+"/img/logo-ct-dark.png"} className="inline h-full max-w-full transition-all duration-200 dark:hidden ease-nav-brand max-h-8" alt="main_logo" />
    <img src={window.home+"/img/logo-ct.png"} className="hidden h-full max-w-full transition-all duration-200 dark:inline ease-nav-brand max-h-8" alt="main_logo" />
    <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand">whatsapp Bot</span>
    </a>
    </div>
    <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
    <div className="items-center block w-auto max-h-screen overflow-auto h-sidenav grow basis-full">
    <ul className="flex flex-col pl-0 mb-0">
    <li className="mt-0.5 w-full">
    <Link to="/" className={classItem+" "+selected['dashboard']}>
    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-sm"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Dashboard</span>
    </Link>
    </li>
    <li className="mt-0.5 w-full">
     <Link to="/chats" className={classItem+" "+selected['chats']}>
    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-orange-500 fa fa-comments text-sm"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Chats</span>
    </Link>
    </li>
    <li className="mt-0.5 w-full">
     <Link to="/customers" className={classItem+" "+selected['customers']}>
    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-red-500 ni ni-single-02 text-sm"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Customers</span>
    </Link>
    </li>
    <li className="mt-0.5 w-full">
    <Link to={'/campaigns/'} className={classItem+" "+selected['campaigns']}>
      <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-emerald-500 text-sm fa fa-bullhorn"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Campaign</span>
    </Link>
    </li>
    <li className="mt-0.5 w-full">
    <Link to={'/customer/segments'} className={classItem+" "+selected['segments']}>
      <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-blue-500 text-sm fa fa-bookmark"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Segments</span>
    </Link>
    </li>
    <li className="mt-0.5 w-full">
    <Link to={'/botgpt'} className={classItem+" "+selected['gpt']}>
      <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-cyan-500 text-sm fa fa-microchip"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">GPT Expert</span>
    </Link>
    </li>
    {window.user?.superbot>0&&<li className="mt-0.5 w-full">
    <Link to={'/botgpt/config'} className={classItem+" "+selected['gpt']}>
      <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-cyan-500 text-sm fa fa-microchip"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">GPT Config</span>
    </Link>
    </li>}
    <li className="w-full mt-4">
    <h6 className="pl-6 ml-2 font-bold leading-tight uppercase dark:text-white text-xs opacity-60">Account pages</h6>
    </li>
    <li className="mt-0.5 w-full">
    <a className=" dark:text-white dark:opacity-80 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors" href="#">
    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
    <i className="relative top-0 leading-normal text-slate-700 text-sm ni ni-single-02"></i>
    </div>
    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Profile</span>
    </a>
    </li>
    </ul>
    </div>
    <div className="mx-4">
    
    <p className="invisible hidden text-gray-800 text-red-500 text-red-600 text-blue-500 dark:bg-white bg-slate-500 bg-gray-500/30 bg-cyan-500/30 bg-emerald-500/30 bg-orange-500/30 bg-red-500/30  after:bg-gradient-to-tl after:from-zinc-800 after:to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 after:bg-gradient-to-tl after:from-blue-700 after:to-cyan-500  after:bg-gradient-to-tl after:from-orange-500 after:to-yellow-500  after:bg-gradient-to-tl after:from-green-600 after:to-lime-400  after:bg-gradient-to-tl after:from-red-600 after:to-orange-600  after:bg-gradient-to-tl after:from-slate-600 after:to-slate-300 text-emerald-500 text-cyan-500 text-slate-400"></p>
    
    </div>
    </aside>
  }
}

export default Sidebar;
