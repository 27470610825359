import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import ChatsList from '../components/Tables/ChatsList'
import ChatDetail from '../components/ChatDetail'
import withRouter from '../components/Layouts/withRouter'
import SocketEventHandler from '../utils/SocketEventHandler'
let guid=null
let pid=0
class Chats extends React.Component{
  state={chats:null, chat:null, newchat:0, nav:false}
  constructor() { 
    super();
  }
componentDidMount(){ 
  const {params}=this.props
  if(params.id){
    this.getChatDetail(params.id)
    SocketEventHandler.on('chat',this.HandleChatEmit.bind(this))
  }else{
    this.getChats()
    SocketEventHandler.on('chat',this.HandleConvEmit.bind(this))  
  }  
}
componentDidUpdate(){
  const {params}=this.props
  if(params.id && params.id!==pid){
    pid = params.id
    this.getChatDetail(params.id)
    SocketEventHandler.on('chat',this.HandleChatEmit.bind(this))    
  }
}
HandleChatEmit(data){ 
  const {chat}=this.state
  const {params}=this.props
  if(guid !==data.message.guid && chat){
    console.log('onchat ')
    guid = data.message.guid
    chat.messages.push(data.message)
    this.setState({chat})
    if(params)this.readChatDetail(params.id)
    let au = new Audio('/notif.wav')
    au.play()
  }else{
    console.log('onchat not')
  }
}
HandleConvEmit(data){
  const {chats,newchat}=this.state
  if(guid !==data.message.guid){
    guid = data.message.guid
    let chat = chats.filter((c)=>c.cid==data.contact)
    if(chat[0]){ 
      chat[0].messages.push(data.message)
      this.setState(chats)
    }else{
      let nc = newchat
      nc++
      this.setState({newchat:nc})
    }
    let au = new Audio('/notif.wav')
    au.play()
  }

}
getChatDetail(id){
  guid=null
  fetch(window.baseurl+'/chat/'+id,{
    headers:{
      'content-type':'application/json',
      'token':window.user?.token
    }
  }).then((r)=>r.json()).then((res)=>{
    this.readChatDetail(id)
    this.setState({chat:res.data})
  })
}

readChatDetail(id){
  fetch(window.baseurl+'/chat/'+id+'/read',{
    method:'post',
    headers:{
      'content-type':'application/json',
      'token':window.user?.token
    }
  }).then((r)=>r.json()).then((res)=>{
   
  })
}

  getChats(){
    guid=null
    fetch(window.baseurl+'/chats',{
      headers:{
        'content-type':'application/json',
        'token':window.user?.token
      }
    }).then((r)=>r.json()).then((res)=>{ window.chats=res.data
      if(res.data){
        let chats = res.data.sort((a,b)=>new Date(b.updated_at)-new Date(a.updated_at))
        this.setState({chats:chats})        
      }
    })
  }
  OnReply(f, cb){
    const {params}=this.props
    const {chat}=this.state
    fetch(window.baseurl+'/chat/'+params.id,{
      method:'POST',
      headers:{
        'content-type':'application/json',
        'token':window.user?.token
      },
      body:JSON.stringify({message:f.message, contact:chat.customer.contact})
    }).then((r)=>r.json()).then((res)=>{
      cb()
      chat.messages.push(res.data)
      this.setState({chat})
    })

  }
  onClickNewChat(){
    this.getChats()
    this.setState({newchat:0})
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  render(){
    const {chats,chat, newchat,nav}=this.state
    const {params}=this.props
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'chats'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header title={'Chats'} toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      <div className="w-full px-6 py-6 mx-auto">
        {newchat>0&&<button
        onClick={this.onClickNewChat.bind(this)}
        className=" pb-6 pt-2 text-white rounded-t-lg top-16 right-6 left-6 absolute bg-green-400">Ada {newchat} pesan baru</button>
}
      {params.id?
      <ChatDetail
      data={chat}
      OnReply={this.OnReply.bind(this)}
      />
      :<ChatsList 
      title={'Chats'}
      data={chats}
      />}
      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(Chats);
