import React from "react";


class Image extends React.Component{
  
  constructor() {
    super();
    
  }
  render(){
    var round = 'rounded-xl';
    var s = '12'
    const {src,circle, size} = this.props
    if(circle){
      round ='rounded-circle'
    }
    if(size){
      s =this.props.size
    }
    const _src = src?src:'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-tailwind/img/team-1.jpg'
   
    return <img
    src={_src}
    alt="avatar image"
    class={"inline-flex items-center justify-center w-"+s+" h-"+s+" mr-2 text-white object-cover  transition-all duration-200 ease-in-out text-sm "+round}
  />
  }
}

export default Image;
