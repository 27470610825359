import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import Customer from '../components/Tables/Customer'
import {
  Link, json
} from "react-router-dom";
class CustomerList extends React.Component{
  state={customers:null, selected:[], all:true,nav:false}
  constructor() {
    super();
  }
componentDidMount(){
  this.getCustomer()
}
  getCustomer(){
    fetch(window.baseurl+'/customers',{
      headers:{
        "Content-Type": "application/json",
        'token':window.user?.token 
      },

    }).then((r)=>r.json()).then((res)=>{
      this.setState({customers:res,selected:[]})
    })
  }
  onCheckList(data){
    this.setState({selected:data})
  }
  onSelectAll(){
    let {all,customers}=this.state
   if(customers){
    customers.forEach(c => {
      c.selected=all
    });
    let l = customers.filter((c)=>c.selected)  
    this.setState({selected:l,all:!all})
   } 
  }
  onDelete(){
    let {selected}=this.state
    fetch(window.baseurl+"/customers/delete",{method:'post',
    headers:{
      "Content-Type": "application/json",
      'token':window.user?.token 
    },
    body:JSON.stringify({customers:selected})
  }).then((res)=>res.json()).then((js)=>{
    this.getCustomer()
  })

  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  render(){
    const {customers,nav}=this.state
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'customers'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      <div className="w-full px-6 py-6 mx-auto">
      <Customer data={customers}
      onCheckList={this.onCheckList.bind(this)}
      onSelectAll={this.onSelectAll.bind(this)}
      onDelete={this.onDelete.bind(this)}
      button={true}
      crud={true}
      />

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default CustomerList;
