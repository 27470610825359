import React from "react";
import CustomFields from '../Modals/CustomField'


class CustomerForm extends React.Component{
  state={agreed:false, locationError:"", coordinate:null,formdata:{}, custprop:null}
  constructor() {
    super();
    
  }
  componentDidMount(){
   const {customer}=this.props
   console.log('did',customer)
   if(customer){
    this.setState({formdata:customer})
   }
  }
  componentDidUpdate(){
    const {customer}=this.props
    let {custprop}=this.state
    if(customer && (customer!==custprop)){
      this.setState({formdata:customer,custprop:customer})
    }

  }
setAgreed(i){
  this.setState({agreed:i})
}

submit(){
  let {formdata}=this.state
  fetch(window.baseurl+"/customer/adds",{method:'post',
  headers:{
    "Content-Type": "application/json",
    'token':window.user?.token 
  },
  body:JSON.stringify(formdata)
}).then((res)=>res.json()).then((js)=>{
  window.Swal.fire({
    icon: 'success',
    title: 'Berhasil...',
    text: 'Data customer berhasil disimpan!',
  })
})
}
OnChange(e){
  let {formdata}=this.state
  formdata[e.target.name]=e.target.value
  this.setState({formdata})
  /*window.socket.timeout(5000).emit('create', {value:e.target.value}, () => {
    console.log('socket is sending: '+e.target.value)
  });
  */
}
  render(){
    const {formdata}=this.state
    return  <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div
      className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
      <div
        className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Customer Form</h2>
     
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="sm:col-span-2">
        <div>
          <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
            Nama Customer
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="name"
              value={formdata.name}
              id="name"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
           onChange={this.OnChange.bind(this)}
           />
          </div>
        </div>
        
        <div className="sm:col-span-2">
          <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-900">
            Nomor Hp
          </label>
          <div className="relative mt-2.5">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <label htmlFor="country" className="sr-only">
                Nomor HP
              </label>
              <select
                id="country"
                name="country"
                className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-3 pr-4 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
              >
                <option>62</option>
              </select>
            </div>
            <input
              type="tel"
              name="contact"
              value={formdata.contact}
              id="phone"
              onChange={this.OnChange.bind(this)}
              autoComplete="tel"
              className="block w-full rounded-md border-0 px-3.5 py-2 pl-16 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
       
        <div className="sm:col-span-2">
          <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2.5">
            <input
              type="email"
              name="email"
              value={formdata.email}
              id="email"
              autoComplete="email"
              onChange={this.OnChange.bind(this)}
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
            Alamat
          </label>
          <div className="mt-2.5">
            <textarea
              name="address"
              value={formdata.address}
              id="message"
              rows={4}
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={''}
            />
          </div>
        </div>
        
      </div>
      <div className="mt-10">
        <button
          type="button"
          onClick={this.submit.bind(this)}
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Simpan
        </button>
      </div>
    </form>
  </div>
  }
}

export default CustomerForm;
