
class SocketEventHandlerClass{
  events=[]
  trigger(name,value){  
    if(!this.events[name]) return
    for(var i =0; i<this.events[name].length;i++){
      if(this.events[name]){
       if(this.events[name][i]) this.events[name][i](value)
      }
    }
  }
  on(name,fn){ 
    if(!this.events[name]){
      this.events[name]=[]
    }
    this.events[name].push(fn)
    window.socketeventhanler = this
  }
}
const SocketEventHandler = window.socketeventhanler===undefined?new SocketEventHandlerClass():window.socketeventhanler

export default SocketEventHandler