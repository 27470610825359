import './App.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { io } from 'socket.io-client';
import Dashboard from './views/Dashboard'
import CustomerForms from './views/CustomerForms'
import CustomerDetail from './views/CustomerDetail'
import CustomerDocuments from './views/CustomerDocuments'
import CustomerList from './views/CustomerList'
import SegmentList from './views/SegmentList'
import Login from './views/Login'
import SegmentCustomerList from './views/SegmentCustomerList'
import Campaign from './views/Campaign'
import CampaignList from './views/CampaignList'
import CampaignMessageList from './views/CampaignMessageList'
import Botgpt from './views/Botgpt'
import BotgptFlow from './views/BotgptFlow'
import Chats from './views/Chats'
import Qr from './views/Qr'
import Register from './views/Register'
import SocketEventHandler from './utils/SocketEventHandler'
import Home from './views/Home'

// "undefined" means the URL will be computed from the `window.location` object
const URL = window.ws;
 const socket = io(URL, {secure: true,
  autoConnect: true,
  query: {
    apikey: "my-value"
  }
});
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
    errorElement: {},
    children: [
    ],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: {},
    children: [
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: {},
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: {},
  },
  {
    path: "/customer/:id/documents",
    element: <CustomerDocuments/>,
    errorElement: {}
  },
  {
    path: "/customer/:id",
    element: <CustomerDetail/>
  },
  {
    path: "/customer/form",
    element: <CustomerForms />,
    errorElement: {},
  },
  {
    path: "/customer/form/:id",
    element: <CustomerForms />,
    errorElement: {},
  },
  {
    path: "/customer/segments",
    element: <SegmentList />,
    errorElement: {},
  },
  {
    path: "/customer/segment/:id",
    element: <SegmentCustomerList />,
    errorElement: {},
  },
  {
    path: "/customers",
    element: <CustomerList />,
    errorElement: {},
  },
  {
    path: "/qr",
    element: <Qr />,
    errorElement: {},
  },
  {
    path: "/botgpt",
    element: <Botgpt />,
    errorElement: {},
  },
  {
    path: "/botgpt/:id",
    element: <Botgpt />,
    errorElement: {},
  },
  {
    path: "/botgpt/flow/:id/:fid",
    element: <BotgptFlow />,
    errorElement: {},
  },
  {
    path: "/chat/:id",
    element: <Chats />,
    errorElement: {},
  },
  {
    path: "/chats",
    element: <Chats />,
    errorElement: {},
  },
  {
    path: "/campaign",
    element: <Campaign />,
    errorElement: {},
  },
  {
    path: "/campaign/:id",
    element: <CampaignMessageList />,
    errorElement: {},
  },
  {
    path: "/campaigns",
    element: <CampaignList />,
    errorElement: {},
  },
]);
function App() {
  socket.on('connect', ()=>{
    console.log('socket is connected')
  }); 
  socket.on(window.user?.bot_id, (qr)=>{ console.log(window.user?.bot_id, qr) 
    window.qr=qr
    SocketEventHandler.trigger(qr.key,qr)
  });

  socket.on('ready', (d)=>{ 
    SocketEventHandler.trigger('ready',d)
  });
  window.socket = socket;
  return (
    <RouterProvider router={router} />
  );
}
export default App;
