import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import Image from '../components/Image'
import Leeds from '../components/Tables/Leeds'
import {
  Link
} from "react-router-dom";
import withRouter from '../components/Layouts/withRouter'
class CustomerDetail extends React.Component{
  constructor() {
    super();
  }
componentDidMount(){
  console.log(this.props)
}
  render(){
    
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header/>
      
      <div className="w-full px-6 py-6 mx-auto">
      <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent bg-white px-6 sm:py-4 lg:px-8">
      <div>
        <div className="flex w-full justify-between flex-wrap md:flex-nowrap"> 
        <div className="flex w-full md:w-1/2">                   
      <Image
      size="20"
      circle
      />
      <div>
        <div className="text-lg font-semibold mt-4">
        Customer Name
        </div>
        <span>
          ID : MKC0001
        </span>
      </div>
        </div>
        
        <div className="w-full max-w-full px-3 xl:w-1/2 xl:flex-none">

<div className="flex flex-wrap -mx-3 justify-end ">
<div className="w-full max-w-full px-3 w-1/3 md:w-1/3 md:flex-none ">
      
      <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="p-4 mx-6 mb-0 text-center border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
      </div>
      <div className="flex-auto p-4 pt-0 text-center">
      <h6 className="mb-0 text-center dark:text-white">Limit Kredit</h6>
      <hr className="h-px my-4 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent"/>
      <h5 className="mb-0 dark:text-white pb-2">Rp.500.000</h5>
      </div>
      </div>
      </div>
<div className="w-full max-w-full px-3 w-1/3 md:w-1/3 md:flex-none">
<div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
<Link to={"documents"} className="p-4 mx-6 mb-0 text-center border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
<div className="w-16 h-16 text-center bg-center shadow-sm icon bg-gradient-to-tl from-blue-500 to-violet-500 rounded-xl">
<i className="relative text-xl leading-none text-white opacity-100 ni ni-folder-17 top-31/100" aria-hidden="true"></i>
</div>
</Link>
<Link to={"documents"} className="flex-auto p-4 pt-0 text-center">
<h6 className="mb-0 text-center dark:text-white">Dokumen</h6>
</Link>
</div>
</div>
<div className="w-full max-w-full px-3  w-1/3 md:mt-0 md:w-1/3 md:flex-none">
<div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
<div className="p-4 mx-6 mb-0 text-center border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
<div className="w-16 h-16 text-center bg-center shadow-sm icon bg-gradient-to-tl from-blue-500 to-violet-500 rounded-xl">
<i className="relative text-xl leading-none text-white opacity-100 ni ni-badge top-31/100" aria-hidden="true"></i>
</div>
</div>
<div className="flex-auto p-4 pt-0 text-center">
<h6 className="mb-0 text-center dark:text-white">Kontak</h6>
</div>
</div>
</div>
</div>
</div>

        </div>
      </div>
<hr className="mt-6"/>
<Leeds />
        </div>

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(CustomerDetail);
