import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import CustomerForm from '../components/Forms/CustomerForm'
import withRouter from '../components/Layouts/withRouter'

class CustomerForms extends React.Component{
  state={customer:null,nav:false}
  constructor() {
    super();
  }
  componentDidMount(){
    const{params}=this.props
    if(params.id) this.getCustomer(params.id)
  }
  getCustomer(id){    
  fetch(window.baseurl+"/customer/"+id,{
  headers:{
    "Content-Type": "application/json",
    'token':window.user?.token 
  },
}).then((res)=>res.json()).then((js)=>{
  this.setState({customer:js.data})
})
  }
  toggleNav(){
    const {nav}=this.state
    this.setState({nav:!nav})
  }
  render(){
    const{customer, nav}=this.state
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'customers'} />
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      
      <div className="w-full px-6 py-6 mx-auto">
      <CustomerForm customer={customer}/>

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(CustomerForms);
