import React from "react";
import Sidebar from '../components/Layouts/Sidebar'
import Header from '../components/Layouts/Header'
import Footer from '../components/Layouts/Footer'
import Customer from '../components/Tables/Customer'
import withRouter from '../components/Layouts/withRouter'
class SegmentCustomerList extends React.Component{
  state={customers:null, all:true,segment:null,customers_selected:[], nav:false}
  constructor() {
    super();
  }
componentDidMount(){
  this.getCustomer()
}
getCustomer(){
    fetch(window.baseurl+'/customers/segment/'+this.props.params.id,{
      headers:{
        "Content-Type": "application/json",
        "token":window.user.token
      },

    }).then((r)=>r.json()).then((res)=>{
      this.setState({segment:res})
    })
  }
title_modal(segment){
  return <div>Tambahkan Customer ke Segment <b>{segment?.name}</b></div>
}
OnCheckList(data){
  let {customers}=this.state
  customers=data
  this.setState({customers,customers_selected:data})
}
onSelectAll(){
  const {all,segment}=this.state
  let customers = segment.customers
 if(customers){
  customers.forEach(c => {
    c.selected=all
  });
  let l = customers.filter((c)=>c.selected)   
  segment.customers=customers
  this.setState({segment,customers_selected:l,all:!all})
 } 
}
onDelete(){
  const{customers_selected}=this.state   
  fetch(window.baseurl+'/customers/segment/'+this.props.params.id+"/delete",{
    method:'post',
    headers:{
      "Content-Type": "application/json",
      "token":window.user.token
    },
  body:JSON.stringify({data:customers_selected})
  }).then((res)=>res.json()).then((js)=>{
    this.getCustomer()
  })
}
toggleNav(){
  const {nav}=this.state
  this.setState({nav:!nav})
}
  render(){
    const {segment, nav}=this.state
    const {params}=this.props
    return (  <div>
      <div className="absolute w-full bg-blue-500 dark:hidden min-h-75"></div>
      
    <Sidebar 
      open={nav}
      item={'segments'}/>
      
      <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      
      <Header toggleNav={this.toggleNav.bind(this)}
      open={nav}/>
      
      <div className="w-full px-6 py-6 mx-auto">
      <Customer
      title={"Customer Segment "+segment?.name}
      title_modal={this.title_modal(segment)}
      modal_able
      segment_id={params?.id}
      data={segment?.customers}
      onCheckList={this.OnCheckList.bind(this)}
      onSelectAll={this.onSelectAll.bind(this)}
      onDelete={this.onDelete.bind(this)}
      onCustomerAddedtoSegment={this.getCustomer.bind(this)}
      />

      <Footer/>
      </div>
      
      </main>
     
     </div>)
  }
}

export default withRouter(SegmentCustomerList);
