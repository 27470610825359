import React from "react";
import {
  Link
} from "react-router-dom";
class Campaign extends React.Component{
  state={customers:null, modalAddForm:false}
  constructor() {
    super();
  }
  componentDidUpdate(){
    const {customers}=this.state
    if(customers!==this.props.data)  this.setState({customers:this.props.data})
  }

  reOrder(){
    let {customers}=this.state
    customers = customers.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({customers})
  }
  
  render(){
    const {title, data}=this.props
    return (<div className="flex-none w-full max-w-full px-3">
    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div className="p-6 pb-0 mb-0 flex justify-between border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
    <h6 className="dark:text-white">{title}</h6>
    <div>
    <Link to={'/campaign'} className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-blue-500 to-violet-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white">Baru</Link>
      </div>
    </div>
    <div className="flex-auto px-0 pt-0 pb-2">
    <div className="p-0 overflow-x-auto ps">
    {data&&<table className="items-center justify-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
    <thead className="align-bottom">
    <tr>
    <th className="py-3 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
      <div className="flex px-2">
    <div className="">
    <input type="checkbox" 
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
    </div>
    </div></th>
    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Nama</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Segment</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Sent</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Delivered</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Read</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Created At</th>
    </tr>
    </thead>
    <tbody className="border-t">
      {data.map((c,i)=>{
        return   <tr key={i}>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <div className="flex px-2">
    <div className="">
    <input 
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
    </div>
    </div>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <div className="flex px-2">
    <div className="my-auto">
   <Link to={'/campaign/'+c._id}> <h6 className="mb-0 text-sm leading-normal dark:text-white whitespace-nowrap">{c.name}</h6></Link>
    </div>
    </div>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.segment?.name}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.sent}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.delivered}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.read}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.created_at}</p>
    </td>
    </tr>
  
})}
    </tbody>
    </table>}
    </div>
    </div>
    </div>
    </div>)
  }
}

export default Campaign;
