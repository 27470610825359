import React from "react";
import SegmentCustomerForm from '../Modals/SegmentCustomerForm'
import {
  Link
} from "react-router-dom";
class Customer extends React.Component{
  state={customers:null, modalsegment:false, customers_selected:[], all:true}
  constructor() {
    super();
  }
  componentDidUpdate(){
    const {customers}=this.state
    if(customers!==this.props.data)  this.setState({customers:this.props.data})
  }
  componentDidMount(){
    if(this.props.selectAll)this.props.onSelectAll()
  }

  reOrder(){
    let {customers}=this.state
    customers = customers.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({customers})
  }
  modalSegment(){
    const {modalsegment}=this.state
    this.setState({modalsegment:!modalsegment})
  }
  onSelected(c){
    const {customers}=this.state
    const{onCheckList}= this.props
    c.selected=!c.selected 
    let l = customers.filter((c)=>c.selected)   
    this.setState({customers,customers_selected:l},  onCheckList(l))
  }
  onCustomerAddedtoSegment(data){
    const {segment_id}=this.props
    let ok=null
     fetch(window.baseurl+'/customers/segment/'+segment_id,{
        method:'post',
        headers:{ 'Content-Type':'Application/json', 
        'token':window.user?.token},
        body:JSON.stringify({data:data})
      }).then((r)=>{ 
        ok=r.ok
        return r.json();      
      }).then((res)=>{  console.log('ok',ok)
        if (ok) {
          this.setState({modalsegment:false})
          this.props.onCustomerAddedtoSegment()
        }else{
          return Promise.reject(res);
       }
      }).catch((err)=>{ })
  }
  onSelectAll(e){
    const {onSelectAll}=this.props
    const {customers,all}=this.state
    let s = all?customers:[]
    this.setState({customers_selected:s,all:!all},  onSelectAll(e))

  }
  render(){
    const {modalsegment, customers_selected}=this.state
    const {title, title_modal,modal_able,data,onSelectAll,button, onDelete, crud}=this.props
    return (<div className="flex-none w-full max-w-full px-3">
    <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div className="p-6 pb-0 mb-0 flex justify-between border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
    <h6 className="dark:text-white">{title}</h6>
    <div>
      {modal_able&&<SegmentCustomerForm visible={modalsegment}
      title={title_modal}
      onSelectAll={onSelectAll}
      onCancel={this.modalSegment.bind(this)}
      onSave={this.onCustomerAddedtoSegment.bind(this)}
      />}
      
   {(!crud&&customers_selected.length>0)&&<button 
    onClick={onDelete}
    type="button" className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-red-500 to-red-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white"><i className="fa fa-trash"></i> Hapus dari segment</button>
  }
    {modal_able&&<button 
    onClick={this.modalSegment.bind(this)}
    type="button" className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-blue-500 to-violet-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white">Tambah Customer</button>
    }  
    
    {(crud&&customers_selected.length>0)&&<>
      {customers_selected.length==1&&<Link
    to={'/customer/form/'+customers_selected[0]._id}
    type="button" className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-orange-500 to-orange-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white"><i className="fa fa-edit"></i> Edit</Link>}
   <button 
    onClick={onDelete}
    type="button" className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-red-500 to-red-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white"><i className="fa fa-trash"></i> Hapus</button>
   
   </> }


    {button&&<Link 
    to={'/customer/form'}
    type="button" className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-blue-500 to-violet-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white">
      Tambah Customer</Link>
    }  
      </div>
    </div>
    <div className="flex">
    <div className="relative flex flex-wrap items-stretch m-6 w-52 transition-all rounded-lg ease">
    <span className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
      <i class="fas fa-search" aria-hidden="true"></i>
    </span>
    <input type="text" class="pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow" placeholder="Cari customer..." />
  </div>
  
  {customers_selected.length>0 &&<div className="mt-7">Dipilih {customers_selected.length} </div>}
    </div>
    <div className="flex-auto px-0 pt-0 pb-2">
    <div className="p-0 overflow-x-auto ps">
    {data&&<table className="items-center justify-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
    <thead className="align-bottom">
    <tr>
    <th className="py-3 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
      <div className="flex px-2">
    <div className="">
    <input type="checkbox"
    onChange={this.onSelectAll.bind(this)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
    </div>
    </div></th>
    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Nama  <button type="button" class="inline-block px-6 py-3 mr-3 font-bold text-center text-blue-500 uppercase align-middle transition-all rounded-lg cursor-pointer bg-blue-500/0 leading-normal text-xs ease-in tracking-tight-rem bg-150 bg-x-25 hover:bg-blue-500/25 hover:-translate-y-px active:bg-blue/45"><i class="fas fa-arrow-up"></i></button>
</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Phone</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Email</th>
    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Alamat</th>
    </tr>
    </thead>
    <tbody className="border-t">
      {data.map((c,i)=>{
        return   <tr key={i}>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <div className="flex px-2">
    <div className="">
    <input
    checked={c.selected}
    onChange={()=>this.onSelected(c)}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
    </div>
    </div>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <div className="flex px-2">
    <div className="my-auto">
    <h6 className="mb-0 text-sm leading-normal dark:text-white">{c.name}</h6>
    </div>
    </div>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.contact}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <p className="mb-0 text-sm font-semibold leading-normal dark:text-white dark:opacity-60">{c.email}</p>
    </td>
    <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
    <span className="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">{c.address}</span>
    </td>
    
    </tr>
  
})}
    </tbody>
    </table>}
    {data?.length<1&&<div className="m-6  p-4 text-white bg-orange-300 rounded-lg"><b>Kosong!</b> Data customer kosong </div>}
    
        </div>
    </div>
   
    </div>
    </div>)
  }
}

export default Customer;
