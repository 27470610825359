import React from "react";
import Image from '../Image'

class Item extends React.Component{
  state={closeable:false, overlay:true, src:null,file:null}
  constructor(props) {
    super();
    const {file}=props
    if(file) this.OnReadFile(file)
  }
  componentDidUpdate(){
    const {file}=this.state
    if(file && file.name!=this.props.file.name){
      this.OnReadFile(this.props.file)
    }
  }
  OnReadFile(file){ 
    // FileReader support
    if (FileReader && file) {
      var fr = new FileReader();
      fr.onload = ()=>this.addFile(fr.result,file)
      fr.readAsDataURL(file);
  }
  }
  addFile(src,file){
    this.setState({src:src, closeable:true,file})
  }
  render(){
    const {closeable, overlay, src}=this.state
    const {OnClose,xkey,file}=this.props
   return  <div className="rounded-lg relative w-32 h-32 min-w-0 overflow-hidden break-words bg-white border-0  shadow-3xl bg-clip-border">
{overlay&&<div className="rounded-lg w-32 h-32 bg-black/30 absolute top-0 content-center grid p-2" an="overlay">
 
<div className="w-full mb-5" an="progress">
  <div className="flex mb-1 text-white">
    <span className="mr-2 font-semibold leading-normal capitalize text-xs">{file.name}</span>
    <span className="ml-auto font-semibold leading-normal text-xs">80%</span>
  </div>
  <div>
    <div className="h-0.75 text-xs flex overflow-visible rounded-lg bg-gray-200">
      <div className="bg-gradient-to-tl from-blue-700 to-cyan-500 w-4/5 transition-width duration-600 ease rounded-1 -mt-0.4 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap text-center text-white"></div>
    </div>
  </div>
</div>
</div>}
{closeable&&<button type="button" onClick={OnClose} className="border bg-black/40 w-6 h-6 absolute  top-1 right-1 rounded-circle"><i className="relative leading-6 text-blue-500 ni ni-fat-remove text-white text-lg"></i></button>}

<Image size="32" src={src} />
   </div>
  }
}

export default Item;
