import React from "react";


class GptForm extends React.Component{
  state={reason:null, formdata:{}, context:null}
  constructor() {
    super();
    
  }
  componentDidMount(){
    this.getContext()
  }

askGPT(i,str){
  let {formdata,context}=this.state
  context.flows[i].action=str
  context.flows[i].asking=true
  formdata.flow = context.flows[i]
  context.flows[i].solutions=context.flows[i].solutions?context.flows[i].solutions:[]
  let url ='/askgpt'
  if(context.flows[i].type==='action') url = '/askai'
  this.setState({context})
  let ok=false
  fetch(window.baseurl+url,{
    headers:{
      'content-type':'application/json', 'token':window.user?.token
    },
    method:'post',
    body:JSON.stringify(formdata)
  }).then((r)=>{ 
    ok=r.ok
    return r.json();      
  }).then((res)=>{ 
    if (ok) {      
      context.flows[i].solutions.push(res.data)
      context.flows[i].asking=false
    this.setState({formdata:formdata,context:context,reason:{},formdata:{}})
    }else{
      return Promise.reject(res);
    }
  }).catch((err)=>{ 
  
})
 
}

getContext(){
  let ok=false
  fetch(window.baseurl+'/bot/context/'+window.user.bot_id,{
    headers:{
      'content-type':'application/json', 'token':window.user?.token
    }
  }).then((r)=>{ 
    ok=r.ok
    return r.json();      
  }).then((res)=>{ 
    if (ok) {      
      this.setState({context:res.data})
    }else{
      return Promise.reject(res);
    }
  }).catch((err)=>{ 
  
})
}
rejected(i){
  const {context}=this.state
  context.flows[i].rejected=1
  this.setState({context})
}
rejectSubmited(i){
  const {context,reason}=this.state
  context.flows[i].rejected=2
  context.flows[i].reason=reason
  this.setState({context})
  this.askGPT(i,"reject")
}
OnChange(e){
  let {reason}=this.state
  reason=e.target.value
  this.setState({reason})
}
OnChangeForm(e){
  let {formdata}=this.state
  formdata[e.target.name]=e.target.value
  this.setState({formdata})
}
  render(){
    const {formdata,context}=this.state
    return  <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div
      className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
      <div
        className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bot GPT</h2>
     
    </div>
    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
      <div className="sm:col-span-2">
       {context?.flows&&<ul>
        {context.flows.map((f,i)=>{
          return <li key={i} className="shadow-lg p-4 rounded-md">
              <div className="flex justify-between border-b-2 pb-2">
              <div >{f.name}</div>
              <button type="button" 
              onClick={()=>{
                if(f.type!=='action'){
                  this.askGPT(i,"")
                }else{
                  context.flows[i].open=!context.flows[i].open
                 this.setState({context})
                }
              }}
              className="inline-block px-6 py-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-blue-500 to-violet-500 leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md">
                {f.asking?<i className="fa fa-circle-o-notch animate-spin"></i>:<i className="fa fa-play"></i>}</button>
              </div>
              {(f.type==='action'&&f.open)&&<div className="mt-4">
               <pre className="whitespace-break-spaces">{f.content}
              </pre>
              {f.fields.map((act,ai)=>{
               return <div className="flex mt-4 justify-start">
              
                  <div className="flex mt-4 ">
                    <label className="w-72">{act.label}</label>
                    <select 
                    name={act.name}
                    onChange={this.OnChangeForm.bind(this)}
              className="block w-36 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option >Pilih</option>
            {act.options.map((o,oi)=>{
              return <option key={oi} value={o.value}>{o.text}</option>
            })}
            </select>
                  </div>
                  
                
              </div>})}
              <div className="flex justify-end mt-4">
              <button
              onClick={()=>this.askGPT(i,"")}
              type="button" className="mx-1 px-6 py-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-green-500 to-green-500 leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md">
             Prompt</button>
             </div>
               </div>}
               
               
              {f.solutions&&<div className="mt-4">
                {f.solutions.map((s,si)=>{
                  return <p className="text-sm text-gray-700 border-b-2">{s}</p>
                })}
              
            {f.rejected===1&&<input 
              type="text"
              name="reason"
              onChange={this.OnChange.bind(this)}
              value={formdata.name}
              placeholder="Tuliskan alasan penolakan anda"
              className="block w-full mt-4 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          
           />}
                {f.type!=='action'&&<div className="flex mt-4 justify-end">
              {!f.rejected&&<><button
               onClick={()=>this.askGPT(i,"other")}
                type="button" className="inline-block  mx-1 px-6 py-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-blue-500 to-violet-500 leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md">
               Minta Solusi lain</button>
              <button type="button" 
              onClick={()=>this.rejected(i)}
              className="inline-block  mx-1 px-6 py-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-red-500 to-orange-500 leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md">
               Tolak Solusi</button>
               </>}
               {f.rejected===1&&<button
              onClick={()=>this.rejectSubmited(i)}
               type="button" className="inline-block  mx-1 px-6 py-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-red-500 to-orange-500 leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md">
               Submit</button>}

                </div>}
              </div>}
          </li>
        })}
       </ul>}
        
      </div>
    </form>
  </div>
  }
}

export default GptForm
